@import 'Styles/includes';

/**
*
* PageResourceList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.PageResourceList {
    &__Wysiwyg {
        padding: 24px 15px;

        @include media(md) {
            max-width: 756px;
            margin: 0 auto;
            padding: 48px 0;
        }
    }

    &__ResourceList {
        @include u-wrap(article);
    }
}
